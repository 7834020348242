import { useRef } from 'react';
import { Button, Card, Footer, Hero, Link } from 'react-daisyui';
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaPhone, FaTwitter } from 'react-icons/fa';

import turks from './assets/Grace-Bay-Beach-Turks-and-caicos-Islands.jpg';
import hero1 from './assets/hero-1.jpg';
import logo from './assets/logo.png';
import trelawny from './assets/melia-braco-village.jpg';
import niagara from './assets/niagara.jpg';
import trinityLogo from './assets/Trinity-Logo.png';
import { ContactForm } from './Contact';

export function App() {
  const form = useRef<HTMLDivElement>(null);
  const cards = [
    {
      title: 'TTR - The Cove',
      desc: 'Private Enclave - Intimate & Secluded Cove on the North Coast of Jamaica',
      location: 'Salem, Runaway Bay',
      link: 'https://thetrinityresort.com/',
      image: 'https://thetrinityresort.com/wp-content/uploads/2021/06/2Scrolls-1080p-Beach-Front3-1.jpg',
    },
    { title: 'TTR - Oasis', desc: 'COMING SOON', location: 'Braco, Trelawny', link: '#', image: trelawny },
    { title: 'TTR - Splendor', desc: 'COMING SOON', location: 'Niagara Falls, Ontario', link: '#', image: niagara },
    { title: 'TTR - Inhabited', desc: 'COMING SOON', location: 'Turks & Caicos', link: '#', image: turks },
  ];
  const social = [
    { Icon: FaFacebook, href: 'https://www.facebook.com/thetrinityresort' },
    { Icon: FaTwitter, href: 'https://twitter.com/JulietTerrelon1' },
    { Icon: FaLinkedin, href: 'https://www.linkedin.com/company/thetrinityresort/' },
    { Icon: FaInstagram, href: 'https://www.instagram.com/thetrinityresort/' },
  ];
  const contact = [
    {
      Icon: FaPhone,
      label: (
        <>
          Office: <Link href="tel:416-747-1717">416-747-1717</Link> <br /> Toll Free: <Link href="tel:1-888-777-9313">1-888-777-9313</Link>
        </>
      ),
    },
    {
      Icon: FaMapMarkerAlt,
      label: (
        <>
          7550 Birchmount rd
          <br />
          Markham, Ontario
          <br />
          L3R 6C6 Canada
        </>
      ),
    },
    { Icon: FaEnvelope, label: <Link href="mailto:info@2scrollsresort.com">info@2scrollsresort.com</Link> },
  ];
  return (
    <main className="flex flex-col lg:flex-row min-h-screen w-screen max-w-full overflow-hidden">
      <section className="lg:fixed lg:w-[50vw]" style={{ background: `url("${hero1}")`, backgroundSize: 'cover' }}>
        <Hero className="h-screen">
          <Hero.Overlay className="bg-opacity-60" />
          <Hero.Content className="text-center text-neutral-content">
            <div className="max-w-md">
              <img className="w-1/3 mx-auto" src={trinityLogo} alt="The Trinity Resort logo" />
              <h1 data-testid="title" className="text-5xl font-bold">
                2Scrolls Resort Inc.
              </h1>
              <div className="py-6">
                <p className="mb-2">We Provide a Portfolio of Diverse, Tranquil & Serene Relaxation for Your Mind, Body & Soul's Inner Peace.</p>
                <p>Holistic Spiritual Retreats, Yours to Discover!</p>
                <h2 className="mt-6 mb-2 text-3xl font-bold">Our Brand of Resorts</h2>
                <div>
                  <a href="https://thetrinityresort.com/" target="_blank" rel="noopener noreferrer">
                    <img className="mx-auto max-w-[75px]" src={logo} alt="logo" />
                  </a>
                  <p className="text-lg">The Trinity Resort</p>
                </div>
              </div>
              <Button className="mx-2" color="ghost" variant="outline" onClick={() => form.current?.scrollIntoView({ behavior: 'smooth' })}>
                Have a question?
              </Button>
              {/* <Button className="mx-2" color="primary">
                Notify Me!
              </Button> */}
            </div>
          </Hero.Content>
          <div className="grid grid-flow-col gap-4 mt-auto mb-4">
            {social.map(({ Icon, ...args }, i) => (
              <Link key={i} {...args} color="ghost">
                <Icon className="hover:text-white" />
              </Link>
            ))}
          </div>
        </Hero>
      </section>
      <section className="relative lg:w-[50vw] h-full bg-base-100 -translate-x-full left-full">
        <Hero className="bg-base-200">
          <Hero.Content className="text-center">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold capitalize">Your ALL Inclusive Vacation Retreat Getaway</h1>
              <div className="py-6">
                <p className="mb-4">
                  Our Resorts are comprised of all your requirements for Luxury, Relaxation, Serene Beauty, Holistic and Spiritual atmosphere, Adorned
                  with an Ocean & Garden Maze vista view ambiance.
                </p>
                <p>Your time spent with us will leave a memorable and lasting impression. We have The KEY to unlock Your Purpose!</p>
              </div>
            </div>
          </Hero.Content>
        </Hero>
        <div className="grid grid-cols-2">
          {cards.map(({ title, image, desc, location, link }, i) => (
            <Link className="flex group no-underline" href={link} key={i} hover={false} target="_blank" rel="noopener noreferrer">
              <Card className="hover:before:opacity-60" bordered={false} imageFull>
                <Card.Image
                  className="object-cover scale-105 opacity-60 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300"
                  src={image}
                  alt={title}
                />
                <Card.Body className="text-center my-auto">
                  <Card.Title className="justify-center leading-5" tag="h2">
                    {title}
                  </Card.Title>
                  <div className="text-base-content/70 text-sm -mt-2">{location}</div>
                  <p className="mt-4">{desc}</p>
                </Card.Body>
              </Card>
            </Link>
          ))}
        </div>
        <Hero className="contact bg-base-300 relative">
          <Hero.Content className="text-center py-11">
            <div>
              <h1 className="text-5xl font-bold mb-12">GET IN TOUCH!</h1>
              <div className="grid grid-cols-3 gap-8">
                {contact.map(({ Icon, label }, i) => (
                  <div key={i} className="items-center">
                    <Icon className="fill-primary mx-auto mb-2" />
                    <p className="break-words">{label}</p>
                  </div>
                ))}
              </div>
            </div>
          </Hero.Content>
        </Hero>
        <ContactForm ref={form} />
        <Footer className="p-4 place-content-center bg-base-100">
          <div>
            <p>Copyright © 2022 - All right reserved by 2Scrolls Resort Inc.</p>
          </div>
        </Footer>
      </section>
    </main>
  );
}
